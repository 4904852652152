/**
 * Re-initializes Vue components loaded via infinite-scroll.
 *
 * When new content is loaded with infinite-scroll, Vue components like
 * TrackerEmbedTippy lose their Vue instance. This function iterates through
 * all tracker elements and checks if they have already been mounted.
 * If not, it extracts the props from the element's attributes,
 * retrieves the slot content, and mounts a new Vue instance with
 * the TrackerEmbedTippy component, passing the props and slot content.
 *
 * This ensures that trackers continue to function properly even after infinite-scroll
 * loads new content.
 */

import {
 createApp, h,
} from 'vue';
import { useEventListener } from '@vueuse/core';
import { convertToKebabCase } from '@/js/helpers/convertToKebabCase';

type PropType = Record<string, string>;

interface ComponentWithInternalName {
    __name?: string;
}

export const reinitializeVueComponent = <
    ComponentType extends ComponentWithInternalName,
    PropsType = object,
>(component: ComponentType) => {
    const mountedElements = new Set<Element>();
    // eslint-disable-next-line no-underscore-dangle
    const componentName = convertToKebabCase(component.__name);

// Convert props to the correct types and cases for 'Vue' from 'blade.php'
    const setPropTypes = (props: PropType) => {
        const filteredProps = {} as PropsType;

        Object.keys(props).forEach((key) => {
            let newValue: string | number | boolean = props[key];
            let newKey: string = key;

            if (key.includes('-')) {
                const dashIndex = key.indexOf('-');
                const mutatedKey = key.replace('-', '').replace(key[dashIndex], key[dashIndex].toUpperCase());

                newKey = mutatedKey.replace(mutatedKey[dashIndex], mutatedKey[dashIndex].toUpperCase());
            }

            if (key.includes(':')) {
                newKey = newKey.replace(':', '');

                if (newValue === 'true') {
                    newValue = true;
                } else if (newValue === 'false') {
                    newValue = false;
                } else {
                    newValue = Number(newValue);
                }
            }

            filteredProps[newKey] = newValue;
        });

        return filteredProps;
    };

// Get the props for the element from HTML
    const getElementProps = (trackerElement: Element) => {
        const attributes = Array.from(trackerElement.attributes);

        const props: PropType = {};

        attributes.forEach((attribute) => {
            props[attribute.name] = attribute.value;
        });

        return setPropTypes(props);
    };

    const reinitializeComponents = () => {
        const trackerElementList = document.querySelectorAll(componentName);

        if (trackerElementList) {
            trackerElementList.forEach((trackerElement) => {
                if (mountedElements.has(trackerElement)) {
                    return;
                }

                try {
                    const props = getElementProps(trackerElement);
                    const slotContent = trackerElement.innerHTML;

                    // Creates a new Vue instance for the component
                    createApp({
                        render() {
                            return h(component, props, {
                                default: () => h('div', { innerHTML: slotContent }),
                            });
                        },
                    }).mount(trackerElement);
                    mountedElements.add(trackerElement);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error creating new vue instance:', error);
                }
            });
        }
    };

    useEventListener(window, 'infinite-scroll-append', () => {
        try {
            reinitializeComponents();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error handling infinite scroll event:', error);
        }
    });
};
